import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'


function Protected(props) {

    let Links = props.Cmp
    const history = useHistory();
    useEffect(() => {
        if (!localStorage.getItem('user-info')) {
            history.push("/login")
        }
    }, [])
    return (
        <div>
            <Links />
        </div>
    )
}

export default Protected