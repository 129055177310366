import Header from './Header'

function UpdateTask() {
    return (
        <div>
            <Header />
            <h1>Update Task</h1>
        </div>
    )
}

export default UpdateTask