import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Login from './Login'
import Register from './Register'
import AddTask from './AddTask'
import UpdateTask from './UpdateTask'
import Protected from './Protected'
import TaskList from './TaskList'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>

          <Route path="/login">
            <Login />
          </Route>

          {/* <Route path="/register">
            <Register />
          </Route> */}

          <Route path="/add">
            <Protected Cmp={AddTask} />
          </Route>

          <Route path="/update">
            <Protected Cmp={UpdateTask} />
          </Route>

          <Route path="/">
            <Protected Cmp={TaskList} />
          </Route>

        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
