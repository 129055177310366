import Header from './Header'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'


function AddTask() {

    const history = useHistory();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState("");

    async function addProduct() {

        console.warn(name, description, price, file)
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('file', file);

        let result = await fetch("https://system.mycrm.uk/api/addtask", {
            method: "POST",
            body: formData
        });
        alert("file upload succesfully")
    }


    return (
        <div>
            <Header />
            <h1>Add Task</h1>
            <div className="col-sm-2 offset-sm-5">
                <input type="text" className="form-control" placeholder="Task Name" onChange={(e) => setName(e.target.value)} /> <br />
                <textarea className="form-control" placeholder="Task Description" onChange={(e) => setDescription(e.target.value)} /> <br />
                <input type="file" className="form-control" onChange={(e) => setFile(e.target.files[0])} /> <br />
                <button className="btn btn-primary" onClick={addTask}>Add Task</button>
            </div>
        </div>
    )
}

export default AddTask