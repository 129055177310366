import Header from './Header'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'


function Login() {

    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {

        if (localStorage.getItem('user-info')) {
            history.push("/")
        }
    }, [])

    //the function has to be async waitting for the API data
    async function login() {
        // console.warn(email, password);
        // create the object to fetch
        let item = { email, password }
        // console.warn(item)
        let result = await fetch("https://system.mycrm.uk/api/login", {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            },
            body: JSON.stringify(item)
        })

        result = await result.json();
        localStorage.setItem("user-info", JSON.stringify(result))
        history.push("/")

    }



    return (

        <div>
            <Header />
            <h1>Login</h1>
            <div className="col-sm-2 offset-sm-5">
                <input type="text" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} /> <br />
                <input type="text" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} /> <br />
                <button className="btn btn-primary" onClick={login} >Login</button>

            </div>
        </div>
    )
}

export default Login