import Header from './Header'
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'


function TaskList() {

    const [data, setData] = useState([]);
    useEffect(async () => {
        let result = await fetch("https://system.mycrm.uk/api/list");
        result = await result.json();
        setData(result);

    }, [])
    console.warn('result', data)
    return (
        <div>
            <Header />
            <h1>Task List</h1>
            <div className="col-sm-8 offset-sm-2">
                <Table>
                    <thead>
                        <tr>
                            <td>Id</td>
                            <td>Task</td>
                            <td>Description</td>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item) =>
                                <tr>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>

                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default TaskList;